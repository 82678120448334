<template>
  <b-tabs
    v-model="activeTabIndex"
    vertical
    content-class="col-12 col-sm-11 col-md-9 mt-n1 mt-sm-0"
    pills
    nav-wrapper-class="col-md-3 col-sm-1 col-12 mt-sm-1 tournament-tabs"
    nav-class="nav-left flex-row flex-sm-column"
    @input="changeRoute"
  >

    <b-tab>
      <template #title>
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Overview</span>
      </template>
      <tournament-overview
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Settings</span>
      </template>
      <tournament-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="ColumnsIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Structure</span>
      </template>
      <tournament-structure
        v-if="options.general"
        :social-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="UserPlusIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Registrations</span>
      </template>
      <tournament-registrations
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Participants</span>
      </template>
      <tournament-participants
        v-if="options.general"
        :information-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="GiftIcon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Results</span>
      </template>
      <tournament-results
        v-if="options.general"
        :notification-data="options.general"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="Share2Icon"
          size="18"
          class="mr-0 mr-md-50"
        />
        <span class="font-weight-bold d-none d-md-flex">Share</span>
      </template>
      <tournament-share
        v-if="options.general"
        :notification-data="options.general"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import TournamentOverview from './TournamentOverview.vue'
import TournamentSettingGeneral from './TournamentSettingGeneral.vue'
import TournamentStructure from './TournamentStructure.vue'
import TournamentRegistrations from './TournamentRegistrations.vue'
import TournamentParticipants from './TournamentParticipants.vue'
import TournamentResults from './TournamentResults.vue'
import TournamentShare from './TournamentShare.vue'

export default {
  components: {
    BTabs,
    BTab,
    TournamentOverview,
    TournamentSettingGeneral,
    TournamentRegistrations,
    TournamentParticipants,
    TournamentStructure,
    TournamentResults,
    TournamentShare,
  },
  data() {
    return {
      options: {},
      activeTabIndex: 0,
      tabs: [
        'overview',
        'settings',
        'structure',
        'registrations',
        'participants',
        'results',
        'share',
      ],
      tabsRefresh: [
        'registrations',
        'participants',
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.hash': function () {
      this.activeTab()
    },
  },
  beforeCreate() {
    this.$http.get(`/organizer/tournament/${this.$route.params.tournament}`).then(res => {
      this.options = res.data.data
      // console.log(res.data.data)
      // console.log('before create')
    })
  },
  created() {
    // console.log(this.options)
    this.activeTab()
    this.$route.meta.pageTitle = this.$route.params.tournament
    this.$route.meta.breadcrumb[this.$route.meta.breadcrumb.length - 1].text = this.$route.params.tournament
    this.$router.replace({ query: { uid: Date.now() * 1.7 } })
  },
  methods: {
    activeTab() {
      const tab = this.$route.hash.replace('#', '')
      const tabIndex = this.tabs.indexOf(tab)
      if (tabIndex !== -1) {
        this.activeTabIndex = tabIndex
      }
    },
    changeRoute(value) {
      this.$router.push({ hash: this.tabs[value] }).catch(() => {})
      //
      const tab = this.$route.hash.replace('#', '')
      const tabIndex = this.tabsRefresh.indexOf(tab)
      if (tabIndex !== -1) {
        // console.log('---------------')
      }
    },
  },

}
</script>
