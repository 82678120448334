<template>
  <b-row>

    <b-col>
      <b-card
        title="Structure"
        class="mb-4"
      >
        <b-card-text>
          Content Goes Here
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
  },
}
</script>
