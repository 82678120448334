<template>

  <b-row>

    <b-col
      md="6"
      lg="6"
    >
      <b-card
        title="Free Tournament"
        sub-title="BGMI India"
        class="mb-4"
      >
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>
        <b-link class="card-link">
          Card link
        </b-link>
        <b-link class="card-link">
          Another link
        </b-link>
      </b-card>
    </b-col>

    <b-col
      md="6"
      lg="6"
    >
      <b-card
        title="Participants"
        sub-title="BGMI India"
        class="mb-4"
      >
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>
        <b-link class="card-link">
          Card link
        </b-link>
        <b-link class="card-link">
          Another link
        </b-link>
      </b-card>
    </b-col>

    <b-col
      md="6"
      lg="6"
    >
      <b-card
        title="Structure"
        sub-title="BGMI India"
        class="mb-4"
      >
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>
        <b-link class="card-link">
          Card link
        </b-link>
        <b-link class="card-link">
          Another link
        </b-link>
      </b-card>
    </b-col>

    <b-col
      md="6"
      lg="6"
    >
      <b-card
        title="Registrations"
        sub-title="BGMI India"
        class="mb-4"
      >
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>
        <b-link class="card-link">
          Card link
        </b-link>
        <b-link class="card-link">
          Another link
        </b-link>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    BCardText,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    imageLoaded() {
      this.inputImageRenderer()
      // console.log('show cropper')
    },
    updateImage(base64) {
      // console.log(base64)
      this.optionsLocal.image = base64
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.generalData.image = base64
    })
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
